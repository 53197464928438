<template>
  <div>
    <!-- 导航栏 -->
    <NavBar />
    <!-- Banner -->
    <Banner
      :logo="require('@/assets/iflow/组 1@2x.png')"
      :mobile-banner="require('@/assets/iflow/banner-mobile.png')"
      info="创新的漏洞修复解决方案 <br /> 不动源代码彻底修复Web应用层漏洞 <br /> 为传统安全设备防不住的应用层漏洞 <br /> 提供专项修复方案"
      btn-style="background: linear-gradient(90deg, #0A9CED 0%, #099BEC 1%, #0487E1 34%, #017BDB 66%, #0078D9 100%);"
      :right-img="require('@/assets/iflow/Group 36168@2x.png')">
      <div class="banner__tips">
      </div>
    </Banner>
    <Item
      title="应用场景与价值"
      title-color="color: #2967CC;"
      info="iFlow聚焦安全事件与提升对抗能力，适用于对抗驱动和事件驱动下的需求场景。">
      <div class="showcase__main module-a">
        <div class="module-a__item">
          <div class="img">
            <img src="@/assets/iflow/Group 52129@2x.png" alt="">
          </div>
          <div class="main">
            <div class="title f20">安全事件应急处置</div>
            <div class="info text">涉及Web应用中的业务（逻辑）漏洞的安全事件需要应急处理 <br />如漏洞已被攻破或经监管部门通报限期整改等</div>
          </div>
        </div>
        <div class="module-a__item">
          <div class="img">
            <img src="@/assets/iflow/Group 52128@2x.png" alt="">
          </div>
          <div class="main">
            <div class="title f20">漏洞管理</div>
            <div class="info text">当Web应用中发现业务（逻辑）漏洞需要被修复 <br />业务（逻辑）漏洞通常主要通过渗透测试发现</div>
          </div>
        </div>
      </div>
    </Item>
    <Item
      title="拟原生修复：一种新的Web应用层漏洞应对策略"
      title-color="color: #2967CC;"
      info="拟原生修复是一种基于可编程对抗的漏洞修复解决方案。它摒弃了特征过滤和隐藏漏洞的治标方案，从漏洞成因入手，在不修改应用程序源代码、修改二进制代码或重新启动应用程序的情况下，即时建立一个安全策略实施层，在其上采用和原生修复完全一致的修复思路，从根本上修复漏洞。">
      <media>
        <div class="showcase__main module-b">
          <img src="@/assets/iflow/case2.png" alt="">
        </div>
        <template v-slot:mobile>
          <div class="showcase__main module-b">
            <img src="@/assets/iflow/case2-mobile.png" alt="">
          </div>
        </template>
      </media>
    </Item>
    <Item
      title="拟原生修复的优势"
      title-color="color: #2967CC;">
      <Media>
        <div class="showcase__main module-c">
          <div class="module-c__item">
            <img src="@/assets/iflow/case3-item1.png" alt="">
            <img src="@/assets/iflow/case3-item1-hover.png" alt="">
          </div>
          <div class="module-c__item">
            <img src="@/assets/iflow/case3-item2.png" alt="">
            <img src="@/assets/iflow/case3-item2-hover.png" alt="">
          </div>
          <div class="module-c__item">
            <img src="@/assets/iflow/case3-item3.png" alt="">
            <img src="@/assets/iflow/case3-item3-hover.png" alt="">
          </div>
        </div>
        <template v-slot:mobile>
          <div class="showcase__main module-c">
            <img src="@/assets/iflow/case3-mobile-a1.png" alt="">
            <img src="@/assets/iflow/case3-mobile-a2.png" alt="">
            <img src="@/assets/iflow/case3-mobile-a3.png" alt="">
          </div>
        </template>
      </Media>
    </Item>
    <Item
      title="产品特色"
      title-color="color: #2967CC;"
      info="iFlow Web应用热补丁网关采用拟原生修复方式修复Web应用层漏洞，实现对安全事件的快速响应，提升Web应用的攻防对抗能力。 iFlow专攻下一代防火墙、WAF、主机IDS等传统安全防护产品无法防护的漏洞。"
      tips="iFlow除了可以用于修复传统安全产品无法防护的漏洞，还可以实现用户在Web应用交互层面的各种安全需求。例如：防撞库、防暴力破解、防自动注册、防爬、防扫描等。与用户现有的Web安全防护体系形成能力上的补充。"
      tips-style="color: #3B3B3B; text-align: left;">
      <Media>
        <div class="showcase__main module-d">
          <img src="@/assets/iflow/组 6@2x.png" alt="">
          <img src="@/assets/iflow/组 10@2x.png" alt="">
          <img src="@/assets/iflow/组 12@2x.png" alt="">
          <img src="@/assets/iflow/组 15@2x.png" alt="">
          <img src="@/assets/iflow/组 8@2x.png" alt="">
          <img src="@/assets/iflow/组 7@2x.png" alt="">
          <img src="@/assets/iflow/组 11@2x.png" alt="">
          <img src="@/assets/iflow/组 13@2x.png" alt="">
          <img src="@/assets/iflow/组 14@2x.png" alt="">
          <img src="@/assets/iflow/组 9@2x.png" alt="">
        </div>
        <template v-slot:mobile>
          <div class="module-d__mobile">
            <img src="@/assets/iflow/item1-mobile.png" alt="">
            <img src="@/assets/iflow/item2-mobile.png" alt="">
            <img src="@/assets/iflow/item3-mobile.png" alt="">
            <img src="@/assets/iflow/item4-mobile.png" alt="">
            <img src="@/assets/iflow/item5-mobile.png" alt="">
            <img src="@/assets/iflow/item6-mobile.png" alt="">
            <img src="@/assets/iflow/item7-mobile.png" alt="">
            <img src="@/assets/iflow/item8-mobile.png" alt="">
            <img src="@/assets/iflow/item9-mobile.png" alt="">
            <img src="@/assets/iflow/item10-mobile.png" alt="">
          </div>
        </template>
      </Media>
    </Item>
    <Item
      title="补丁仓库"
      title-color="color: #2967CC;"
      info="天存信息将多年来在Web应用安全研究和实践中积累的各种Web应用层漏洞修复经验总结成补丁模版，预置于iFlow的补丁仓库中。<br /><br />iFlow的补丁仓库中现有数百个补丁模版，可用于修复多种复杂的Web应用层安全漏洞。用户可以通过模版类型检索补丁模版，也可以依据适用对象、适用行业、防护技术等标签组成复合条件，更加精准地从补丁仓库中检索出补丁模板。<br /><br />在补丁模版中，对于Web应用层漏洞的绝大部分修复过程都已经予以实现。仅将与漏洞相关的Web应用系统特性数据预留出来以待模版实例化的时候填入。">
      <div class="showcase__main module-b">
        <img src="@/assets/iflow/Group 36195@2x.png" alt="">
      </div>
    </Item>
    <Item
      title="补丁实例"
      title-color="color: #2967CC;"
      info="补丁模版的实例化是补丁模版在漏洞修复实战场景下的应用过程。<br /><br />iFlow之所以称之为热补丁网关，正是因为其上所有的补丁实例都是可以“热插拔”的，即所有补丁实例的启用和关停都可以补丁阵列中的通过的开关即时完成。停用和启用补丁实例均不影响Web应用系统的正常运行。">
      <media>
        <div class="showcase__main module-b">
          <img src="@/assets/iflow/组 16@2x.png" alt="">
        </div>
        <template v-slot:mobile>
          <div class="showcase__main module-b">
            <img src="@/assets/iflow/组 17@2x.png" alt="">
          </div>
        </template>
      </media>
    </Item>
    <Item
      class="page-last"
      title="iFlow部署"
      title-color="color: #2967CC;"
      info="iFlow 作为独立硬件设备，采用代理工作机制">
      <media>
        <div class="showcase__main module-b">
          <img src="@/assets/iflow/组 19@2x.png" alt="">
        </div>
        <template v-slot:mobile>
          <div class="showcase__main module-b">
            <img src="@/assets/iflow/组 18@2x.png" alt="">
          </div>
        </template>
      </media>
    </Item>
    <!-- 底部 -->
    <Footer />
  </div>
</template>>
<script>
import Banner from '../components/banner'
import Item from '../components/item'

export default {
  components: {
    Banner,
    Item
  }
}
</script>
<style lang="scss" scoped>
.banner__tips {
  @media screen and (max-width: 750px) {
    width: 100%;
    border: none;
    border-radius: px2rem(72);
    background: rgba(255, 255, 255, 1);
    padding: px2rem(100) px2rem(50) 0;
    margin-top: -40px;
    box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.1);
  }
}
.module-d__mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  img {
    width: 580px;
    height: 170px;

    &:nth-of-type(n+3) {
      margin-top: 25px;
    }
  }
}
.last {
  margin-bottom: 150px;
}
.showcase__main {
  &.module-a {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 750px) {
      flex-direction: column;

      .module-a__item {
        width: 100% !important;
        flex-direction: row !important;
        padding: 50px;
        border-radius: 12px;
        background: rgba(250, 250, 250, 1);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);

        & + .module-a__item {
          margin-top: 35px;
        }

        .img {
            width: 350px !important;
        }

        .main {
          flex: 1;
          padding-left: 24px;
          .title {
            padding-top: 0 !important;
            font-size: px2rem(48) !important;
            font-weight: 600 !important;
            letter-spacing: px2rem(1) !important;
            line-height: 1.6 !important;
            text-align: justify !important;
          }
          .info {
            padding-top: 30px !important;
            font-size: px2rem(32) !important;
            font-weight: 400 !important;
            letter-spacing: px2rem(1) !important;
            line-height: 1.8 !important;
            text-align: justify !important;
          }
        }
      }
    }

    .module-a__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 75%;

      .img {
        width: 210px;
        font-size: 0;
        img {
          width: 100%;
        }
      }
      .main {
        text-align: center;
        .title {
          padding-top: 50px;
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 1px;
          color: rgba(42, 132, 230, 1);
        }
        .info {
          padding-top: 14px;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 1px;
          color: #666666;
          line-height: 1.8;
        }
      }
    }
  }

  &.module-b {
    font-size: 0;
    width: 74%;
    margin: auto;
    img {
      width: 100%;
    }

    @media screen and (max-width: 750px) {
      flex-direction: column;
      margin-top: 50px;
      width: 100% !important;
    }
  }

  &.module-c {
    display: flex;
    justify-content: space-between;
    width: 74%;
    margin: auto;

    @media screen and (max-width: 750px) {
      flex-direction: column;
      margin-top: 50px;
      width: 100% !important;

      img {
        width: 100% !important;

        & + img {
          margin-top: 50px;
        }
      }
    }

    .module-c__item {
      position: relative;
      width: 30%;
      box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
      border-radius: 12px 12px 12px 12px;
      overflow: hidden;

      &:hover {
        img:nth-of-type(2) {
          top: 0;
        }
      }

      img {
        width: 100%;
        height: 100%;
        &:nth-of-type(2) {
          position: absolute;
          left: 0;
          top: 100%;
          transition: all 0.5s ease-in;
        }
      }
    }
  }

  &.module-d {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 190px;

    @media screen and (max-width: 750px) {
      padding: 0;

      img {
        width: calc(163px * 1.3) !important;
        height: calc(163px * 1.3) !important;
      }
    }

    img {
      width: 163px;
      height: 163px;
      margin-top: 8px;
    }
  }
}
// .showcase__main {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   width: 100%;

//   &.module-a {
//     img {
//       width: 398px;
//       margin: 14px;
//     }
//   }

//   &.module-b {
//     width: 935px;
//     margin: 0 auto;
//     img {
//       width: 163px;
//       margin-bottom: 30px;
//     }
//   }

//   &.module-c {
//     .module-c__item {
//       display: flex;
//       align-items: center;

//       &:nth-child(n+2) {
//         margin-top: 80px;
//       }

//       .module-c__item__logo {
//         width: 137px;
//         height: 137px;
//         margin-right: 22px;

//         img {
//           width: 100%;
//           height: 100%;
//         }
//       }
//       .module-c__item__main {
//         flex: 1;
//         .module-c__item__main__title {
//           font-size: 28px;
//           color: #2A84E6;
//         }
//         .module-c__item__main__info {
//           font-size: 20px;
//           color: #3B3B3B;
//           line-height: 28px;
//           padding-top: 12px;
//         }
//       }
//     }
//   }
// }
</style>
